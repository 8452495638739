import React, { useContext } from 'react'
import Product from './Product'
import { ShopContext } from '../../context/shop-context'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import { Instagram  } from 'react-content-loader';
import { MdWeb } from "react-icons/md";

export default function Shop() {
  const {item} = useContext(ShopContext)
  const [t, i18n] = useTranslation();

  const {storeName} = useParams();
 localStorage.setItem('storeName', storeName);

const {getStoreName, profile, dataIsLoading, handleVertical} = useContext(ShopContext);
getStoreName(storeName);
console.log('type',profile.product_type);

  return (
    <div className="shop">
         {i18n.language == "ar" ?
            <div className="asidebar">
            <div className="asocial">
              <button className='btn btn-warning px-4' onClick={handleVertical}>
      
              <MdWeb size="40px" color='white'/>
            </button>
            </div>
            </div>
      :
      <div className="sidebar">
      <div className="social">
        <button className='btn btn-warning px-4' onClick={handleVertical}>

        <MdWeb size="40px" color='white'/>
      </button>
      </div>
      </div>
      }
      { dataIsLoading ? <Instagram /> :
      <><div className="shopTitle my-3">
        </div><div className="products">
            <div className="container text-start">
              <div className="row">
                {item.map((prdouct) => (
                  <Product key={prdouct.id} data={prdouct} />
                ))}
              </div>
            </div>
          </div>
          <div className="alert text-warning">
            {profile.product_type == 1 ?
              <strong> {t('note2')}</strong> :
              <strong> {t('note')}</strong>}
          </div></>
}
  </div>
  )}